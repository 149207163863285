export const routesList = [
  {
    id: 1,
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    path: "Dashboard",
    permission: true
  },
  {
    id: 2,
    title: "Roles",
    icon: "mdi-account-hard-hat",
    path: "Roles",
    permission: true
  },
  {
    id: 3,
    title: "Usuarios",
    icon: "mdi-account-group",
    path: "Usuarios",
    permission: true
  },
  {
    id: 4,
    title: "Clientes",
    icon: "mdi-account-supervisor",
    group: true,
    permission: true,
    routes: [
      {
        title: "Listado clientes",
        icon: "mdi-account-supervisor",
        path: "Clientes",
        permission: false
      },
      {
        title: "Carritos abandonados",
        icon: "mdi-cart",
        path: "Carritos abandonados",
        permission: false
      },
      {
        title: "BlackList",
        icon: "mdi-cart",
        path: "BlackList",
        permission: false
      }
    ]
  },
  {
    id: 37,
    title: "B2B",
    icon: "mdi-map-marker-star",
    group: true,
    permission: true,
    routes: [
      {
        title: "Usuarios",
        icon: "mdi-account-heart",
        path: "Registro-Usuarios",
        permission: false
      },
      {
        title: "Publicacion",
        icon: "mdi-account-heart",
        path: "B2b-Publication",
        permission: false
      },
      {
        title: "Ventas",
        icon: "mdi-account-heart",
        path: "B2b-Ventas",
        permission: false
      }
    ]
  },
  {
    id: 36,
    title: "Paises",
    icon: "mdi-earth",
    path: "Paises",
    permission: true
  },
  {
    id: 5,
    title: "Productos",
    icon: "mdi-archive",
    path: "Productos",
    permission: true,
    dropdownItem: true
  },
  {
    id: 5,
    title: "Productos",
    icon: "mdi-archive",
    path: "Productos",
    permission: true,
    dropdownItem: true
  },
  {
    id: 59,
    title: "Notificaciones",
    icon: "mdi-archive",
    path: "Notificaciones",
    permission: true
  },
  {
    id: 60,
    title: "Rotación de inventario",
    icon: "mdi-archive",
    path: "Rotacion",
    permission: true
  },
  {
    id: 61,
    title: "Editar codigo de despacho",
    icon: "mdi-archive",
    path: "",
    show: false,
    permission: true
  },
  {
    id: 62,
    title: "Rotación México",
    icon: "mdi-archive",
    path: "",
    show: false,
    permission: true
  },
  {
    id: 63,
    title: "Rotación Chile",
    icon: "mdi-archive",
    path: "",
    show: false,
    permission: true
  },
  {
    id: 64,
    title: "Rotación Argentina",
    icon: "mdi-archive",
    path: "",
    show: false,
    permission: true
  },
  {
    id: 65,
    title: "Rentabilidad publicaciones",
    icon: "mdi-archive",
    path: "Rentabilidad publicaciones",
    permission: true,
    dropdownItem: true
  },
  {
    id: 66,
    title: "Liquidar producto",
    icon: "mdi-archive",
    path: "Liquidar producto",
    show: false,
    permission: true
  },
  {
    id: 67,
    title: "Pausar publicacion ML",
    icon: "mdi-archive",
    path: "Pausar publicacion ML",
    show: false,
    permission: true
  },
  {
    id: 68,
    title: "Productos SKUs",
    icon: "mdi-archive",
    path: "Productos SKUs",
    show: false,
    permission: true
  },
  {
    id: 57,
    title: "Productos",
    icon: "mdi-archive",
    group: true,
    permission: true,
    dropdownBox: true,
    routes: [
      {
        title: "Productos",
        icon: "mdi-archive",
        path: "Productos",
        permission: true
      },
      {
        title: "Rentabilidad publicaciones",
        icon: "mdi-archive",
        path: "Rentabilidad publicaciones",
        permission: true
      },
      {
        title: "Precio Productos",
        icon: "mdi-archive",
        path: "Precio Productos",
        permission: true
      },
      {
        title: "Depositos Productos",
        icon: "mdi-garage",
        path: "Depositos-Productos",
        permission: true
      },
      {
        title: "Reserva Productos",
        icon: "mdi-shopping",
        path: "Reserva Productos",
        permission: true
      },
      {
        title: "Categorías de productos",
        icon: "mdi-archive",
        path: "Categorías de productos",
        permission: true
      },
      {
        title: "Especificaciones de las categorias",
        icon: "mdi-archive",
        path: "Especificaciones de las categorias",
        permission: true
      }
    ]
  },

  {
    id: 56,
    title: "Categorías de productos",
    icon: "mdi-archive",
    path: "Categorías de productos",
    permission: true,
    dropdownItem: true
  },
  {
    id: 58,
    title: "Especificaciones de las categorias",
    icon: "mdi-archive",
    path: "Especificaciones de las categorias",
    permission: true,
    dropdownItem: true
  },
  {
    id: 22,
    title: "Precio Productos",
    icon: "mdi-archive",
    path: "Precio Productos",
    permission: true,
    dropdownItem: true
  },
  {
    id: 16,
    title: "Depositos Productos",
    icon: "mdi-garage",
    path: "Depositos-Productos",
    permission: true,
    dropdownItem: true
  },
  {
    id: 55,
    title: "Preguntas",
    icon: "mdi-comment-question",
    group: true,
    permission: true,
    routes: [
      {
        title: "Preguntas sin responder",
        icon: "mdi-garage-open-variant",
        path: "Preguntas sin responder",
        permission: false
      },
      {
        title: "Historial",
        icon: "mdi-account-heart",
        path: "Historial",
        permission: false
      }
    ]
  },
  {
    id: 54,
    title: "Facturas Argentina",
    icon: "mdi-newspaper",
    path: "Facturas Argentina",
    permission: true
  },
  {
    id: 52,
    title: "Facturas Chile",
    icon: "mdi-newspaper",
    path: "Facturas Chile",
    permission: true
  },
  // {
  //   id: 7,
  //   title: "Movimientos",
  //   icon: "mdi-map-marker-star",
  //   group: true,
  //   permission: true,
  //   routes: [
  //     {
  //       title: "Deposito Deposito",
  //       icon: "mdi-account-heart",
  //       path: "Movimientos",
  //       permission: true,
  //     },
  //     {
  //       title: "Barco Deposito",
  //       icon: "mdi-account-heart",
  //       path: "Barco-Deposito",
  //       permission: true,
  //     },
  //   ],
  // },
  {
    id: 53,
    title: "Trazabilidad de inventario",
    icon: "mdi-star",
    group: true,
    permission: true,
    routes: [
      {
        title: "Depositos",
        icon: "mdi-garage-open-variant",
        path: "Depositos",
        permission: false
      },
      {
        title: "Inventarios por Depósitos",
        icon: "mdi-account-heart",
        path: "Inventarios por Depósitos",
        permission: false
      },
      {
        title: "Transferencias de Inventario",
        icon: "mdi-account-heart",
        path: "Transferencias de Inventario",
        permission: false
      }
    ]
  },
  {
    id: 21,
    title: "Inventario",
    icon: "mdi-scale-balance",
    group: true,
    permission: true,
    routes: [
      {
        title: "Stock Valorizado",
        icon: "mdi-scale-balance",
        path: "Stock Valorizado",
        permission: false
      },
      {
        title: "Stock Proyectado",
        icon: "mdi-file-document-multiple-outline",
        path: "Stock Proyectado",
        permission: false
      },
      {
        title: "Dias Inventario",
        icon: "mdi-scale-balance",
        path: "Dias Inventario",
        permission: false
      },
      {
        title: "Ventas Pesos",
        icon: "mdi-scale-balance",
        path: "Ventas Pesos",
        permission: false
      }
    ]
  },
  {
    id: 8,
    title: "Proveedores",
    icon: "mdi-account-tie",
    path: "Proveedores",
    permission: true
  },
  {
    id: 9,
    title: "Categorias",
    icon: "mdi-semantic-web",
    path: "Categorias",
    permission: true
  },
  {
    id: 28,
    title: "Marcas",
    icon: "mdi-shopping",
    path: "Marcas",
    permission: true
  },
  {
    id: 24,
    title: "Caracteristicas",
    icon: "mdi-semantic-web",
    path: "Caracteristicas",
    permission: true
  },
  {
    id: 10,
    title: "Monedas",
    icon: "mdi-bitcoin",
    path: "Monedas",
    permission: true
  },
  {
    id: 11,
    title: "Tienda",
    icon: "mdi-cart",
    path: "Tienda",
    permission: true
  },
  {
    id: 12,
    title: "Publicaciones",
    icon: "mdi-newspaper",
    path: "Publicaciones",
    permission: true
  },
  {
    id: 20,
    title: "Promociones",
    icon: "mdi-developer-board",
    path: "Promociones",
    permission: true
  },
  {
    id: 23,
    title: "Cargar Imagen",
    icon: "mdi-account-tie",
    path: "Cargar Imagen",
    permission: true
  },
  {
    id: 19,
    title: "Cupones",
    icon: "mdi-ticket-percent",
    path: "Cupones",
    permission: true
  },
  //VENTAS
  {
    id: 13,
    title: "Ventas General",
    icon: "mdi-basket",
    path: "Ventas General",
    permission: true
  },
  {
    id: 48,
    title: "Ventas No Completadas",
    icon: "mdi-basket",
    path: "Ventas No Completadas",
    permission: true
  },
  {
    id: 40,
    title: "Ventas Argentina",
    icon: "mdi-basket",
    path: "Ventas Argentina",
    permission: true
  },
  {
    id: 39,
    title: "Ventas Chile",
    icon: "mdi-basket",
    path: "Ventas Chile",
    permission: true
  },
  {
    id: 46,
    title: "Ventas Mexico",
    icon: "mdi-basket",
    path: "Ventas Mexico",
    permission: true
  },
  {
    id: 38,
    title: "Ventas Borrador AR",
    icon: "mdi-basket",
    path: "Ventas Borrador AR",
    permission: true
  },
  {
    id: 44,
    title: "Ventas Borrador CL",
    icon: "mdi-basket",
    path: "Ventas Borrador CL",
    permission: true
  },
  {
    id: 30,
    title: "Ventas Córdoba",
    icon: "mdi-basket",
    path: "Ventas Córdoba",
    permission: true
  },
  {
    id: 31,
    title: "Ventas Rosario",
    icon: "mdi-basket",
    path: "Ventas Rosario",
    permission: true
  },
  {
    id: 18,
    title: "Ventas Landing",
    icon: "mdi-clipboard-account",
    path: "Ventas Landing",
    permission: true
  },
  {
    id: 14,
    title: "Envios",
    icon: "mdi-truck",
    path: "Envios",
    permission: true
  },
  {
    id: 32,
    title: "Envios Cordoba",
    icon: "mdi-truck",
    path: "Envios Cordoba",
    permission: true
  },
  {
    id: 33,
    title: "Envios Rosario",
    icon: "mdi-truck",
    path: "Envios Rosario",
    permission: true
  },
  {
    id: 41,
    title: "Envios Chile",
    icon: "mdi-truck",
    path: "Envios Chile",
    permission: true
  },
  {
    id: 47,
    title: "Envios Mexico",
    icon: "mdi-truck",
    path: "Envios Mexico",
    permission: true
  },
  {
    id: 17,
    title: "Retiro Cliente",
    icon: "mdi-car-lifted-pickup",
    path: "Retiro Cliente",
    permission: true
  },
  {
    id: 42,
    title: "Retiro Cliente Chile",
    icon: "mdi-car-lifted-pickup",
    path: "Retiro Cliente Chile",
    permission: true
  },
  {
    id: 34,
    title: "Retiro Cordoba",
    icon: "mdi-car-lifted-pickup",
    path: "Retiro Cordoba",
    permission: true
  },
  {
    id: 35,
    title: "Retiro Rosario",
    icon: "mdi-car-lifted-pickup",
    path: "Retiro Rosario",
    permission: true
  },
  {
    id: 15,
    title: "Mercado Libre",
    icon: "mdi-shopping",
    path: "Mercado Libre",
    permission: true
  },
  {
    id: 25,
    title: "Reserva Productos",
    icon: "mdi-shopping",
    path: "Reserva Productos",
    permission: true,
    dropdownItem: true
  },
  {
    id: 26,
    title: "Control de Rentabilidad",
    icon: "mdi-shopping",
    path: "Control de Rentabilidad",
    permission: true
  },
  {
    id: 27,
    title: "Historico de stocks",
    icon: "mdi-shopping",
    path: "Historico de stocks",
    permission: true
  },
  {
    id: 29,
    title: "Transferencias AR",
    icon: "mdi-shopping",
    path: "Transferencias AR",
    permission: true
  },
  {
    id: 43,
    title: "Transferencias Chile",
    icon: "mdi-shopping",
    path: "Transferencias Chile",
    permission: true
  },
  {
    id: 45,
    title: "Preguntas ML",
    icon: "mdi-shopping",
    path: "Preguntas ML",
    permission: true
  },
  {
    id: 50,
    title: "Suscripciones",
    icon: "mdi-shopping",
    path: "Suscripciones",
    permission: true
  },
  {
    id: 51,
    title: "Showroom EDIFIER Chile",
    icon: "mdi-shopping",
    path: "Showroom EDIFIER Chile",
    permission: true
  }
];
